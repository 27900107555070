
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { Empty, notification } from 'antd';

import { userListService, changeStatusService, deleteUserService } from '../../services/userService';
import { userSearchAction, counselorPageNoAction } from '../../store/user';
import { MainLoader, MiniLoader } from '../reuse/SVG';
import ConfirmModal from '../modals/ConfirmModal';



const CounselorList = props => {
    const [miniLoader, setMiniLoader] = useState('');
    const [loader, setLoader] = useState(true);
    const [counselors, setCounselors] = useState([]);
    const [totalCount, setTotalCount] = useState('');
    const [currentUserId, setCurrentUserId] = useState('');
    const [confimDeleteModal, setConfimDeleteModal] = useState(false);

 
    useEffect(() => {
        return () => {
            props.userSearchAction('')
        }
    }, [])


    const changeStatus = user => {
        setMiniLoader({[user._id]: true});

        let params = {
            userId: user._id,
            status: user.status == 1 ? '2' : '1' 
        }

        changeStatusService(params).then(res => {
            getList();
            setMiniLoader('');
            notification.open({
                message: 'User Status',
                description: `User status change successfully to  ${user.status == 1 ? 'Inactive' : 'Active'}`
            });
        }); 
    }


    const getList = () => {
        let params = {
            type: 4,
            search: props.search,
            page: props.counselorPageNo - 1,
            limit: 10
        };
        userListService(params).then(res => {
            if(res.data.statusCode){
                setCounselors(res.data.responseData.result)
                setTotalCount(res.data.responseData.count)
                setLoader(false);
            }else{
             setLoader(false);
            }
        })
    }


    useEffect(() => {
        getList();
    }, [props.counselorPageNo]);


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
                getList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [props.search])


    const handleDelete = () => {
        let query = `?userId=${currentUserId}&type=2`;
        deleteUserService(query).then(res => {
            if(res.data.statusCode){
                closeModal();
                notification.open({
                    message: 'Personnel',
                    description: 'Personnel deleted successfully.'
                });
                getList();
            }
        });
    }


    const handleDeleteModal = id => {
        setCurrentUserId(id);
        setConfimDeleteModal(true);
    }


    const closeModal = () => {
        setCurrentUserId('');
        setConfimDeleteModal(false);
    }


    return (
        
            <div className="table_responsive_wrap mt-4 text-center">
                {loader ? <div className="table_loader"><MainLoader /></div> : ''}
                <div className="table-responsive">
        
                    <table className="table table-bordered table-striped no_t_texts">

                        <thead>
                            <tr>
                            <th><span className="t_text">S.No</span></th>
                                <th><span className="t_text">Camp Name</span></th>
                                <th><span className="t_text">Personnel Email</span></th>
                                <th><span className="t_text">Personnel Name</span></th>
                                <th><span className="t_text">Action</span></th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                counselors.map((user, i) => (
                                    <tr key={user._id}>
                                        <td><span className="t_text">{((i + 1) + ((props.counselorPageNo - 1) * 10))}</span></td>
                                        <td><span className="t_text">{user.campName}</span></td>
                                        <td><span className="t_text">{user.email}</span></td>
                                        <td><span className="t_text">{user.name}</span></td>
                                        <td><span className="t_text">
                                            <i className="icon-delete" onClick={() => handleDeleteModal(user._id)} />
                                            <div className="cm_swich_wrap">
                                                {miniLoader[user._id] ? <div className="mini_loader"><MiniLoader /></div> : <React.Fragment><input type="checkbox" checked={user.status == 1 ? true : false} onChange={() => changeStatus(user)} /><span /></React.Fragment>}
                                            </div>
                                            </span></td>
                                    </tr>
                                ))
                            }
                            
                        
                        </tbody>
                    </table>
                </div>

                {
                    counselors.length ? '' : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
                }

                {
                    counselors.length ? <div className="mt-4">
                        <Pagination
                            activePage={props.counselorPageNo}
                            itemsCountPerPage={10}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={3}
                            onChange={p => props.counselorPageNoAction(p)}/>
                    </div> : ''
                }


            <ConfirmModal
                show={confimDeleteModal}
                hideModal={closeModal}
                msg="Are you sure you want to delete?"
                action={handleDelete} />

            </div>
    )
}


/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    let { search, counselorPageNo } = state.user;
    return {
        search,
        counselorPageNo
    };
}

const mapDispatchToProps = dispatch => ({
    userSearchAction: params => dispatch(userSearchAction(params)),
    counselorPageNoAction: params => dispatch(counselorPageNoAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CounselorList);